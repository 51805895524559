.workflow-run {
    text-align: center;
    margin-bottom: 10px;
}

.workflow-run:last-child {
    margin-bottom: 0;
}

.workflow-run .at {
    font-size: 80%;
}

.workflow-run .status {
    font-size: 80%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.workflow-run .status .icon {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background: orange;
    margin-right: 5px;
    animation-timing-function: ease-in-out;
    animation-name: blink;
    animation-duration: 500ms;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

.workflow-run .status.failure .icon,
.workflow-run .status.cancelled .icon {
    animation: none;
    background: red;
}

.workflow-run .status.success .icon {
    animation: none;
    background: green;
}

.workflow-run .version {
    font-weight: bold;
    margin-bottom: 3px;
}

@keyframes blink {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}