.workflow {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #2727c2;
}

.workflow select {
    max-width: 20vw;
    margin-bottom: 10px;
}

.workflow .workflow-url {
    font-size: 80%;
    margin-bottom: 5px;
}

.workflow .workflow-name {
    font-size: 80%;
    margin-bottom: 5px;
    font-weight: bold;
}

.workflow .workflow-run-overview {
    font-size: 80%;
    margin-bottom: 3px;
    text-decoration: underline;
}