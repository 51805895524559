.repositories {
    flex: 1;
    display: flex;
    flex-direction: row;
}

.repositories .environments {
    flex: 1;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #2727c2;
}

.repositories .environments .environment,
.repositories .environments .header {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #2727c2;
    padding: 10px;
    font-size: 80%;
    position: relative;
}

.repositories .environments .header img {
    width: 80%;
}

.repositories .environments .environment .title {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 10px;
}

.repositories .environments .environment button {
    width: 100%;
    height: 40px;
}

.repositories .environments .header {
    border-top: none;
    font-size: 20px;
    font-weight: bold;
    color: white;
    background-color: #2727c2;
}