.workflows {
    flex: 1;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #2727c2;
}

.workflows:last-child {
    border: none;
}

.workflows .repository {
    flex: 1;
    order: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 80%;
}

.workflows .repository .title {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: bold;
}